@import url(https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Jura', sans-serif !important;
}

body {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  /* background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%); */
}

.app_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Jura', sans-serif !important;
  width: 100%;
}

.main {
  flex: 1 1;
  margin-top: 4em;
}

.box {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  margin-bottom: 32px;
  object-fit: cover;
}

.name {
  font-size: 2.5em;
  font-weight: lighter;
  letter-spacing: 1.5px;
  margin: auto 0;
  margin-top: 10px;
  margin-bottom: 35px;
}

.title {
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.summary {
  letter-spacing: 1.5px;
  font-size: 0.9em;

  margin: 0;
}

.summary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 200%;
}
.mainsummary-container {
  margin-bottom: 40px;
}

.project-header {
  margin-top: 40px;
}

.projects {
  font-size: 2.5em;
  font-weight: lighter;
  letter-spacing: 1.5px;
  color: cornflowerblue;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.icon-row {
  font-size: 17px;
  margin: auto;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  border-radius: 65px;
  box-shadow: 0 0 15px lightgrey;
  padding: 11px 15px;
  background-color: #eee;
  transition: 0.5s;
  color: grey;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.icon:hover {
  color: #ddd;
  background-color: cornflowerblue;
  box-shadow: 0 0px 2px 4px cornflowerblue;
}

.message {
  margin-left: 15px;
  color: cornflowerblue;
}

.footer {
  text-transform: uppercase;
  color: black;
  font-size: 0.8rem;
  margin: 50px 10px 0 0;
}

/** @format */

.image-container {
  margin-bottom: 30px;
}

.project-image {
  box-shadow: rgba(0, 0, 0, 0.148438) 0 1px 2px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.project-image:hover {
  transform: scale(1.1) !important;
}

.image-button {
  outline: none;
  border: none;
  background: none;
}

.image-button:focus {
  outline: none;
}

.modalContainer {
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  padding: 0;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 6px 2px #e9e9e9;
  overflow-y: auto;
  max-height: 700px;
  max-width: 50%;
  outline: none;
  margin: 0 10px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 auto 0 auto;
  width: 100%;
  height: auto;
  background-color: #e1e1e1;
}

.modal-header {
  background-color: #d1cece;
  display: flex;
  text-align: center !important;
  justify-content: center !important;
  align-content: center;
  width: 100%;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 32px;
}

.modal-image {
  max-width: 100%;
  max-height: auto;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: 0 20px;
}

.description {
  margin: 15px auto 15px auto !important;
}

.modal-footer {
  background-color: #d1cece;
  margin-top: 15px;
}

.modal-footer-button {
  color: black;
  font-weight: bold;
  background-color: #e1e1e1;
  border: none;
  padding: 10px 16px;
  border-radius: 5px;
}

.modal-footer-button:focus {
  outline: 0;
}

.modal-footer-button:active {
  transform: scale(0.93);
}

.modal-footer-button:hover {
  background-color: darkgrey;
}

.footer-button {
  padding: 10px 16px;
  border-radius: 5px;
  border: none;
  color: black;
  transition: transform;
  font-weight: bold;
  background-color: #e9e9e9;
}

.footer-button:active {
  transform: scale(0.93);
}

.footer-button:focus {
  outline: 0;
}

.footer-button:hover {
  background-color: #ddd;
}

.close-button {
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
  color: black;
  font-size: 20px;
  transition: transform;
}

.close-button:focus {
  outline: none;
}

.close-button:hover {
  color: #e1e1e1;
}

.close-button:active {
  transform: scale(1.2);
}

.button-container {
  display: flex;
  justify-content: space-around;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}

@media (max-width: 1200px) {
  .modalContainer {
    max-height: 550px;
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .modalContainer {
    width: 80%;
  }
}

@media (max-width: 610px) {
  .modalContainer {
    max-height: 700px;
    width: 80%;
  }
}

@media (max-width: 480px) {
  .modalContainer {
    max-height: 500px;
    width: 100%;
    min-width: 275px;
  }
}

@media (max-width: 420px) {
  .modalContainer {
    max-height: 500px;
    width: 100%;
    min-width: 275px;
  }
}

@media (max-width: 330px) {
  .modalContainer {
    max-height: 500px;
    width: 100%;
    min-width: 275px;
  }
}

@media (max-width: 285px) {
  .modalContainer {
    max-height: 500px;
    min-width: 275px;
  }
}

.skills {
  font-size: 30px;
  color: grey;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 75%;
  padding-top: 30px;
  transition: all 1s ease-in-out;
  cursor: pointer;
}

.html,
.css,
.bs,
.js,
.jquery,
.react,
.graphql,
.apollo,
.node,
.mongo,
.sql,
.git {
  transition: all 0.3s ease-in-out;
}

.html:hover {
  color: #e44d26;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}

.css:hover {
  color: #264de4;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.bs:hover {
  color: #533b78;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.js:hover {
  color: #f7df1e;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.jquery:hover {
  color: #0865a6;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.react:hover {
  color: #5ed3f3;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.graphql:hover {
  color: #d60090;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.apollo:hover {
  color: black;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.node:hover {
  color: #8bbf3d;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.mongo:hover {
  color: #8bbf3d;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.sql:hover {
  color: #005d85;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.git:hover {
  color: #e84d31;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

@media (max-width: 800px) {
  .skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

