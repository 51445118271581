.skills {
  font-size: 30px;
  color: grey;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 75%;
  padding-top: 30px;
  transition: all 1s ease-in-out;
  cursor: pointer;
}

.html,
.css,
.bs,
.js,
.jquery,
.react,
.graphql,
.apollo,
.node,
.mongo,
.sql,
.git {
  transition: all 0.3s ease-in-out;
}

.html:hover {
  color: #e44d26;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}

.css:hover {
  color: #264de4;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.bs:hover {
  color: #533b78;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.js:hover {
  color: #f7df1e;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.jquery:hover {
  color: #0865a6;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.react:hover {
  color: #5ed3f3;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.graphql:hover {
  color: #d60090;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}
.apollo:hover {
  color: black;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.node:hover {
  color: #8bbf3d;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.mongo:hover {
  color: #8bbf3d;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.sql:hover {
  color: #005d85;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

.git:hover {
  color: #e84d31;
  transition: all 0.3 ease-in-out;
  transform: scale(1.3);
}

@media (max-width: 800px) {
  .skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}
