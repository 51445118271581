@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Jura', sans-serif !important;
}

body {
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  /* background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%); */
}

.app_container {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Jura', sans-serif !important;
  width: 100%;
}

.main {
  flex: 1;
  margin-top: 4em;
}

.box {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.profile-pic {
  border-radius: 50%;
  margin-bottom: 32px;
  object-fit: cover;
}

.name {
  font-size: 2.5em;
  font-weight: lighter;
  letter-spacing: 1.5px;
  margin: auto 0;
  margin-top: 10px;
  margin-bottom: 35px;
}

.title {
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.summary {
  letter-spacing: 1.5px;
  font-size: 0.9em;

  margin: 0;
}

.summary-container {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 200%;
}
.mainsummary-container {
  margin-bottom: 40px;
}

.project-header {
  margin-top: 40px;
}

.projects {
  font-size: 2.5em;
  font-weight: lighter;
  letter-spacing: 1.5px;
  color: cornflowerblue;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.icon-row {
  font-size: 17px;
  margin: auto;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  border-radius: 65px;
  box-shadow: 0 0 15px lightgrey;
  padding: 11px 15px;
  background-color: #eee;
  transition: 0.5s;
  color: grey;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.icon:hover {
  color: #ddd;
  background-color: cornflowerblue;
  box-shadow: 0 0px 2px 4px cornflowerblue;
}

.message {
  margin-left: 15px;
  color: cornflowerblue;
}
